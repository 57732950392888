import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const Book = ({ book }) => {
  return (
    <article>
      <Link to={`/book/${book.slug}`} className="text-reset text-decoration-none">
        {book.coverImage && <GatsbyImage className="rounded mb-2" image={getImage(book.coverImage)} alt={`Book Cover for ${book.title}`}/>}
        <h4>{book.title}</h4>
        {book.shortDescription && <p>{book.shortDescription}</p>}
        <p className="text-muted">
          <small>Published {(new Date(book.released)).toLocaleDateString()}</small>
        </p>
      </Link>
    </article>
  )
}

export default Book
