import { graphql } from "gatsby";
import * as React from "react";
import Layout from '../components/layout';
import { Container, Col, Row } from "react-bootstrap";
import Book from '../components/book';

const BooksPage = ({ data }) => {
  return (
    <Layout title="Books">
      <section>
        <Container>
          <h1 className="title">Books</h1>
          <Row>
            {
              data.allGraphCmsBook.nodes.map((node) => (
                <Col xs={6} md={3}>
                  <Book key={node.id} book={node}/>
                </Col>
              ))
            }
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allGraphCmsBook(sort: {fields: publishedAt, order: DESC}) {
      nodes {
        id
        slug
        title
        shortDescription
        released
        coverImage {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`

export default BooksPage
